





























































































import { Vue, Component } from 'vue-property-decorator';

@Component({
	components: {
		BaseToolTip: () => import('../components/base/BaseToolTip.vue')
	}
})
export default class About extends Vue {}
